// App.js
import React, { Suspense } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Loader from "./components/Loader/Loader";
import { ToastContainer } from "react-toastify";
import ScrollToTopButton from './components/Scrollup/ScrollToTopButton';
import WhatsAppIcon from './components/WhatsAppIcon';
import ScrollToTop from './hooks/useWindowScrollToTop';
import '@fortawesome/fontawesome-free/css/all.min.css';
// SecurityWrapper with anti-inspect and anti-DevTools settings
import SecurityWrapper from './components/SecurityWrapper';
// import ResponsiveBackgroundPage from "./components/BackgroundCarousal/BackgroundCarousel";
// Lazy-loaded components for optimized loading
const NavBar = React.lazy(() => import("./components/Navbar/Navbar"));
const Footer = React.lazy(() => import("./components/Footer/Footer"));
const Home = React.lazy(() => import("./pages/Home"));
const PageNotFound = React.lazy(() => import("./pages/CommonPages/NotFound"));
const Location = React.lazy(() => import('./components/Location/Location'));
const TechnflexLocation = React.lazy(() => import('./components/Location/TechnflexLocation'));
const ContactUs = React.lazy(() => import("./pages/ContactUs/ContactUs"));
const MainContactUs = React.lazy(() => import("./pages/ContactUs/MainContactUs"));
const Award = React.lazy(() => import("./pages/awards/award"));
const Update = React.lazy(() => import("./pages/Updates/update"));
const MainUpdate = React.lazy(() => import("./pages/Updates/MainUpdate"));
const Testimonials = React.lazy(() => import('./pages/Testimonials/Testimonials'));
const ManageGalleryItems = React.lazy(() => import('./pages/ProductGallery/GallaryView'));
const Countries = React.lazy(() => import('./pages/MyLocation/contries'));
const TechnoflexOverview = React.lazy(() => import('./pages/About/TechnoflexOverview'));
const TechnoflexMilestones = React.lazy(() => import('./pages/About/TechnoflexMilestones'));
const MainProductsPage = React.lazy(() => import('./components/ProductCard/MainProductsPage'));
const ProductDetailPage = React.lazy(() => import('./components/ProductDetails/ProductDetailPage'));
const Contry = React.lazy(() => import('./pages/MyLocation/viewcontry'));
const MarketArea = React.lazy(() => import('./components/Location/MarketArea'));
const GetQuote = React.lazy(() => import('./pages/ContactUs/GetQuote'));
const BrochureDownload = React.lazy(() => import('./pages/Brochures/BrochureDownload'));
const AccessoriesPage = React.lazy(() => import('./components/ProductCard/AccessoriesPage'));
const AccessoryDetailPage = React.lazy(() => import('./components/ProductDetails/AccessoryDetailPage'));


// ProtectedRoute component for authentication
const ProtectedRoute = ({ element: Element, ...rest }) => {
  const isAuthenticated = true; // Replace with your actual authentication logic
  return isAuthenticated ? <Element {...rest} /> : <Navigate to="/404" replace />;
};

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <SecurityWrapper>
          <ToastContainer
            position="top-right"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <NavBar />
          <ScrollToTop />
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<Home />} />
            {/* <Route path="/backgroundpage:pageName" element={<ResponsiveBackgroundPage />} /> */}
            <Route path="/404" element={<PageNotFound />} />
            <Route path="/brochure/:brochureId" element={<BrochureDownload />} />
            <Route path="/accessoriespage" element={<AccessoriesPage />} />
            <Route path="/accessory/:id" element={<AccessoryDetailPage />} />
            {/* <Route path="/accessory/:name" element={<AccessoryDetailPage />} /> */}
            <Route path="/location" element={<ProtectedRoute element={Location} />} />
            <Route path="/tfloc" element={<ProtectedRoute element={TechnflexLocation} />} />
            <Route path="/contactus" element={<ProtectedRoute element={ContactUs} />} />
            <Route path="/maincontactus" element={<ProtectedRoute element={MainContactUs} />} />
            <Route path="/award" element={<ProtectedRoute element={Award} />} />
            <Route path="/news" element={<ProtectedRoute element={Update} />} />
            <Route path="/update" element={<ProtectedRoute element={MainUpdate} />} />
            <Route path="/testimonials" element={<ProtectedRoute element={Testimonials} />} />
            <Route path="/gallery" element={<ProtectedRoute element={ManageGalleryItems} />} />
            <Route path="/ma" element={<ProtectedRoute element={MarketArea} />} />
            <Route path="/tfove" element={<ProtectedRoute element={TechnoflexOverview} />} />
            <Route path="/tfmile" element={<ProtectedRoute element={TechnoflexMilestones} />} />
            <Route path="/getquote" element={<ProtectedRoute element={GetQuote} />} />
            <Route path="/countries" element={<ProtectedRoute element={Countries} />} />
            <Route path="/product" element={<ProtectedRoute element={MainProductsPage} />} />
            {/* <Route path="/product/:id" element={<ProtectedRoute element={ProductDetailPage} />} /> */}
            <Route path="/product/:name" element={<ProductDetailPage />} />
            <Route path="/vc" element={<ProtectedRoute element={Contry} />} />
           
            {/* Catch-all route */}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
          <WhatsAppIcon />
          <ScrollToTopButton />
          <Footer />
        </SecurityWrapper>
      </Router>
    </Suspense>
  );
}

export default App;
