// WhatsAppIcon.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import zIndex from '@mui/material/styles/zIndex';

const WhatsAppIcon = () => {
    const phoneNumber = '+91 8770608670'; // Replace with your phone number

    return (
        <a 
            href={`https://wa.me/${phoneNumber}`} 
            target="_blank" 
            rel="noopener noreferrer" 
            style={styles.icon}
        >
            <FontAwesomeIcon icon={faWhatsapp} size="2x" />
        </a>
    );
};

const styles = {
    icon: {
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        backgroundColor: '#25D366',
        borderRadius: '50%',
        padding: '10px',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
        color: 'white',
        textDecoration: 'none',
        zIndex: 1000  // Set a specific zIndex value, like 1000
    },
};


export default WhatsAppIcon;
